import { useTheme as t } from "next-themes";
function d() {
  const {
    resolvedTheme: o,
    setTheme: e
  } = t();
  return {
    colorMode: o,
    setColorMode: e,
    toggleColorMode: () => {
      e(o === "light" ? "dark" : "light");
    }
  };
}
export {
  d as default
};
