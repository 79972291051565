import { j as o } from "./jsx-runtime-D0AQ1Mn7.js";
import { ThemeProvider as i } from "next-themes";
import { forwardRef as l } from "react";
import { ClientOnly as a, IconButton as c, Skeleton as d } from "@chakra-ui/react";
import { d as m, e as u } from "./index-KipyBmwD.js";
import t from "./components/chakra/ColorMode/useColorMode.es.js";
const M = (r) => /* @__PURE__ */ o.jsx(i, { attribute: "class", disableTransitionOnChange: !0, ...r }), x = () => {
  const {
    colorMode: r
  } = t();
  return r === "light" ? /* @__PURE__ */ o.jsx(m, {}) : /* @__PURE__ */ o.jsx(u, {});
}, b = l(function(e, n) {
  const { toggleColorMode: s } = t();
  return /* @__PURE__ */ o.jsx(a, { fallback: /* @__PURE__ */ o.jsx(d, { boxSize: "8" }), children: /* @__PURE__ */ o.jsx(
    c,
    {
      onClick: s,
      variant: "ghost",
      "aria-label": "Toggle color mode",
      size: "sm",
      ref: n,
      ...e,
      css: {
        _icon: {
          width: "5",
          height: "5"
        }
      },
      children: /* @__PURE__ */ o.jsx(x, {})
    }
  ) });
});
export {
  M as C,
  b as a
};
