import { j as o } from "../../../jsx-runtime-D0AQ1Mn7.js";
import { defineConfig as e, createSystem as t, defaultConfig as i, ChakraProvider as s } from "@chakra-ui/react";
import { C as m } from "../../../ColorModeButton-CxdU0p6I.js";
import "next-themes";
const n = e({
  theme: {
    tokens: {
      colors: {
        primary: { value: "#F28500" }
      }
    }
  }
}), a = t(i, n);
function p(r) {
  return /* @__PURE__ */ o.jsx(s, { value: a, children: /* @__PURE__ */ o.jsx(m, { ...r }) });
}
export {
  p as Provider,
  p as default
};
