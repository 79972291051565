import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeState } from "@spotswap/shared";
import { store } from "src/redux/store";
import App from "./App";
import Provider2 from "@spotswap/shared/chakra/Provider";

declare module "styled-components" {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  export interface DefaultTheme extends ThemeState {

  }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <Provider2>
      <App />
    </Provider2>
  </Provider>,
);
