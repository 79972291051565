import a from "react";
var p = {
  color: void 0,
  size: void 0,
  className: void 0,
  style: void 0,
  attr: void 0
}, h = a.createContext && /* @__PURE__ */ a.createContext(p), v = ["attr", "size", "title"];
function y(t, r) {
  if (t == null) return {};
  var e = k(t, r), n, o;
  if (Object.getOwnPropertySymbols) {
    var c = Object.getOwnPropertySymbols(t);
    for (o = 0; o < c.length; o++)
      n = c[o], !(r.indexOf(n) >= 0) && Object.prototype.propertyIsEnumerable.call(t, n) && (e[n] = t[n]);
  }
  return e;
}
function k(t, r) {
  if (t == null) return {};
  var e = {};
  for (var n in t)
    if (Object.prototype.hasOwnProperty.call(t, n)) {
      if (r.indexOf(n) >= 0) continue;
      e[n] = t[n];
    }
  return e;
}
function u() {
  return u = Object.assign ? Object.assign.bind() : function(t) {
    for (var r = 1; r < arguments.length; r++) {
      var e = arguments[r];
      for (var n in e)
        Object.prototype.hasOwnProperty.call(e, n) && (t[n] = e[n]);
    }
    return t;
  }, u.apply(this, arguments);
}
function f(t, r) {
  var e = Object.keys(t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(t);
    r && (n = n.filter(function(o) {
      return Object.getOwnPropertyDescriptor(t, o).enumerable;
    })), e.push.apply(e, n);
  }
  return e;
}
function d(t) {
  for (var r = 1; r < arguments.length; r++) {
    var e = arguments[r] != null ? arguments[r] : {};
    r % 2 ? f(Object(e), !0).forEach(function(n) {
      L(t, n, e[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(e)) : f(Object(e)).forEach(function(n) {
      Object.defineProperty(t, n, Object.getOwnPropertyDescriptor(e, n));
    });
  }
  return t;
}
function L(t, r, e) {
  return r = x(r), r in t ? Object.defineProperty(t, r, { value: e, enumerable: !0, configurable: !0, writable: !0 }) : t[r] = e, t;
}
function x(t) {
  var r = b(t, "string");
  return typeof r == "symbol" ? r : r + "";
}
function b(t, r) {
  if (typeof t != "object" || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var n = e.call(t, r);
    if (typeof n != "object") return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
function g(t) {
  return t && t.map((r, e) => /* @__PURE__ */ a.createElement(r.tag, d({
    key: e
  }, r.attr), g(r.child)));
}
function i(t) {
  return (r) => /* @__PURE__ */ a.createElement(j, u({
    attr: d({}, t.attr)
  }, r), g(t.child));
}
function j(t) {
  var r = (e) => {
    var {
      attr: n,
      size: o,
      title: c
    } = t, m = y(t, v), s = o || e.size || "1em", l;
    return e.className && (l = e.className), t.className && (l = (l ? l + " " : "") + t.className), /* @__PURE__ */ a.createElement("svg", u({
      stroke: "currentColor",
      fill: "currentColor",
      strokeWidth: "0"
    }, e.attr, n, m, {
      className: l,
      style: d(d({
        color: t.color || e.color
      }, e.style), t.style),
      height: s,
      width: s,
      xmlns: "http://www.w3.org/2000/svg"
    }), c && /* @__PURE__ */ a.createElement("title", null, c), t.children);
  };
  return h !== void 0 ? /* @__PURE__ */ a.createElement(h.Consumer, null, (e) => r(e)) : r(p);
}
function O(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "path", attr: { d: "M20 6 9 17l-5-5" }, child: [] }] })(t);
}
function P(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "path", attr: { d: "m9 18 6-6-6-6" }, child: [] }] })(t);
}
function C(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "rect", attr: { width: "18", height: "11", x: "3", y: "11", rx: "2", ry: "2" }, child: [] }, { tag: "path", attr: { d: "M7 11V7a5 5 0 0 1 10 0v4" }, child: [] }] })(t);
}
function W(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "path", attr: { d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" }, child: [] }, { tag: "polyline", attr: { points: "16 17 21 12 16 7" }, child: [] }, { tag: "line", attr: { x1: "21", x2: "9", y1: "12", y2: "12" }, child: [] }] })(t);
}
function M(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "line", attr: { x1: "4", x2: "20", y1: "12", y2: "12" }, child: [] }, { tag: "line", attr: { x1: "4", x2: "20", y1: "6", y2: "6" }, child: [] }, { tag: "line", attr: { x1: "4", x2: "20", y1: "18", y2: "18" }, child: [] }] })(t);
}
function B(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "path", attr: { d: "M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" }, child: [] }] })(t);
}
function E(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "rect", attr: { width: "18", height: "18", x: "3", y: "3", rx: "2" }, child: [] }, { tag: "path", attr: { d: "M15 3v18" }, child: [] }] })(t);
}
function _(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "circle", attr: { cx: "11", cy: "11", r: "8" }, child: [] }, { tag: "path", attr: { d: "m21 21-4.3-4.3" }, child: [] }] })(t);
}
function S(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "circle", attr: { cx: "12", cy: "12", r: "4" }, child: [] }, { tag: "path", attr: { d: "M12 2v2" }, child: [] }, { tag: "path", attr: { d: "M12 20v2" }, child: [] }, { tag: "path", attr: { d: "m4.93 4.93 1.41 1.41" }, child: [] }, { tag: "path", attr: { d: "m17.66 17.66 1.41 1.41" }, child: [] }, { tag: "path", attr: { d: "M2 12h2" }, child: [] }, { tag: "path", attr: { d: "M20 12h2" }, child: [] }, { tag: "path", attr: { d: "m6.34 17.66-1.41 1.41" }, child: [] }, { tag: "path", attr: { d: "m19.07 4.93-1.41 1.41" }, child: [] }] })(t);
}
function N(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "path", attr: { d: "M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" }, child: [] }, { tag: "circle", attr: { cx: "12", cy: "7", r: "4" }, child: [] }] })(t);
}
function z(t) {
  return i({ attr: { viewBox: "0 0 24 24", fill: "none", stroke: "currentColor", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, child: [{ tag: "path", attr: { d: "M18 6 6 18" }, child: [] }, { tag: "path", attr: { d: "m6 6 12 12" }, child: [] }] })(t);
}
export {
  z as L,
  O as a,
  P as b,
  _ as c,
  S as d,
  B as e,
  M as f,
  N as g,
  C as h,
  W as i,
  E as j
};
